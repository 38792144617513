import requestor from "@/utils/requestor"

export default {
  async login(data) {
    return await requestor._post('/user/login/login', data)
  },
  async register(data) {
    return await requestor._post('/user/login/register', data)
  },
  async getConfig() {
    return await requestor._get('/common/getConfig')
  },
  async getService() {
    return await requestor._get('/common/getService')
  },
  async getBanner(data) {
    return await requestor._get('/common/getBanner', data)
  },
  async getUserInfo() {
    return await requestor._get('/user/personal/userInfo')
  },
  async getBank() {
    return await requestor._get('/user/bank/get')
  },
  async getProductList() {
    return await requestor._get('/product/getList')
  },
  async getProductDetail(data) {
    return await requestor._post('/product/getDetail', data)
  },
  async createOrder(data) {
    return await requestor._post('/order/create', data)
  },
  async editBank(data) {
    return await requestor._post('/user/bank/edit', data)
  },
  async delteBank(data) {
    return await requestor._post('/user/bank/delete', data)
  },
  async getWithdrawlConfig(data) {
    return await requestor._post('/common/getWithdrawConfig', data)
  },
  async withdraw(data) {
    return await requestor._post('/user/wallet/withdraw', data)
  },
  async getOrderList(data) {
    return await requestor._post('/order/getList', data)
  },
  async setPassword(data) {
    return await requestor._post('/user/personal/setPassword', data)
  },
  async getOrderDetail(data) {
    return await requestor._post('/order/getDetail', data)
  },
  async getWithdrawOrder(data) {
    return await requestor._post('/user/wallet/getWithdrawOrder', data)
  },
  async getKline(data) {
    return await requestor._post('/k_line/getList', data)
  },
}